<script setup lang="ts">
import { ref, computed } from "@vue/reactivity";
import { RedeemFAQData } from "../../api.generated/scion";

const props = defineProps<{
  faq: RedeemFAQData;
  primaryColour: string;
}>();

const isExpanded = ref<boolean>(false);

const booleanAnswer = computed(() => {
  if (props.faq.isBooleanAnswer === false) {
    return;
  }

  switch (props.faq.rawAnswer.toUpperCase()) {
    case "YES":
      return "Yes\xa0";
    case "NO":
      return `No\xa0\xa0`;
    case "OUI":
      return "Oui\xa0";
    case "NON":
      return "Non";
    default:
      return;
  }
});
</script>

<template>
  <div class="py-4">
    <div v-if="faq.isBooleanAnswer">
      <div class="flex items-center">
        <div class="mr-4 flex-1 font-normal">
          {{ faq.question }}
        </div>

        <div
          class="flex items-center self-start"
          :style="{ color: primaryColour }"
        >
          <svg
            v-if="faq.booleanAnswer"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="mr-2 w-10"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="mr-2 w-10"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>

          <span v-if="faq.isBooleanAnswer" class="font-semibold">{{
            booleanAnswer
          }}</span>
        </div>
      </div>
    </div>

    <div v-else>
      <div
        @click="isExpanded = !isExpanded"
        class="flex cursor-pointer items-center"
      >
        <span class="mr-4 flex-1 font-normal">
          {{ faq.question }}
        </span>
        <svg
          v-if="!isExpanded"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-8 self-start"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-8 self-start"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 15.75l7.5-7.5 7.5 7.5"
          />
        </svg>
      </div>
      <div :class="[isExpanded ? 'block' : 'hidden', 'py-26 print:block']">
        {{ faq.rawAnswer }}
      </div>
    </div>
  </div>
</template>

<style scoped></style>
