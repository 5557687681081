<script setup lang="ts">
import { ref } from "@vue/reactivity";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ProductSpendTypeEnum, RedeemFAQData } from "../../api.generated/scion";
import { FAQAnswer, TwoTabs } from "../index";
import { Tab } from "../TwoTabs/index.vue";

const { t } = useI18n();

const props = defineProps<{
  faqs: RedeemFAQData[];
  primaryColour: string;
}>();

const onlineFAQs = ref<RedeemFAQData[]>(
  props.faqs.filter((faq) => faq.spendingType === ProductSpendTypeEnum.ONLINE)
);
const inStoreFAQs = ref<RedeemFAQData[]>(
  props.faqs.filter((faq) => faq.spendingType === ProductSpendTypeEnum.IN_STORE)
);

const online_msg = computed(() => t("message.online"));
const in_store_msg = computed(() => t("message.in_store"));

const tabs = computed(() => {
  const tabs = [
    onlineFAQs.value && onlineFAQs.value.length
      ? { label: online_msg, target: "online", isActive: false }
      : null,
    inStoreFAQs.value && inStoreFAQs.value.length
      ? { label: in_store_msg, target: "in-store", isActive: false }
      : null,
  ].filter((t) => t) as any;

  if (tabs && tabs.length) {
    tabs[0].isActive = true;
  }

  return tabs;
});
</script>

<template>
  <div class="p-4">
    <h2 class="mb-6 text-2xl font-semibold">
      {{ $t("message.frequently_asked_questions") }}
    </h2>

    <TwoTabs :tabs="tabs">
      <template #online>
        <FAQAnswer
          v-for="faq in onlineFAQs"
          class="border-b border-gray-200 last:border-0 last:pb-0"
          :key="faq.question"
          :faq="faq"
          :primaryColour="props.primaryColour"
        >
        </FAQAnswer>
      </template>

      <template #in-store>
        <FAQAnswer
          v-for="faq in inStoreFAQs"
          class="border-b border-gray-200 last:border-0 last:pb-0"
          :key="faq.question"
          :faq="faq"
          :primaryColour="props.primaryColour"
        >
        </FAQAnswer>
      </template>
    </TwoTabs>
  </div>
</template>

<style scoped></style>
